import React from 'react'
import { Container, Button } from 'react-bootstrap'

import Icon from '../Icon'

import * as s from './Hero.module.scss'

const Hero = () => {
  return (
    <section className={s.hero}>
      <Container className={s.hero__inner}>
        <h1>
          Prophet, the one
          <br /> validator you need
        </h1>
        <div className={s.hero__bottom}>
          <Button href="#stake" size="main">
            Stake
            <Icon name="arrow" size={32} />
          </Button>
          <p className="lead">
            Prophet is a team of real crypto enthusiasts, who love working with
            infrastrucure and source code.
          </p>
        </div>
      </Container>
    </section>
  )
}

export default Hero
