import React from 'react'
import { Button } from 'react-bootstrap'

import Icon from '~components/Icon'
import ApiValue from './ApiValue'

import * as s from './Item.module.scss'

const Item = ({
  bg: backgroundColor,
  color,
  img,
  title,
  address,
  data,
  button,
}) => {
  return (
    <div className={s.item} style={{ backgroundColor, color }}>
      <div className={s.item__img}>
        <img {...img} alt={title} />
      </div>
      <h3>{title}</h3>
      <table className={s.table}>
        <tbody>
          {address && (
            <tr className={s.table__address}>
              <th>Address:</th>
              <td>
                {/* <input type="text" value={address} readOnly /> */}
                {address}
              </td>
            </tr>
          )}
          {data.map(({ name, value, api }) => (
            <tr key={name}>
              <th>{name}:</th>
              <td>{api ? <ApiValue {...{ ...api, value }} /> : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {button && (
        <Button
          size="arrow"
          href={button.link}
          target="_blank"
          className={s.item__button}
        >
          {button.label}
          <Icon name="arrow" size={26} />
        </Button>
      )}
    </div>
  )
}

export default Item
